import React from "react"
import { graphql, PageProps } from "gatsby"
import SEO from "../../components/seo"
import { Query } from "../../../types/graphql-types"
import Layout from "../../components/layout"
import PageTitle from "../../components/utils/PageTitle"
import "../../components/Company/style.css"
import { en_description } from "../../components/const"

const Company: React.FC<PageProps<Query>> = ({ data, location }) => {
  const companyData = data.microcmsCompanyEn
  return (
    <Layout location={location} selectPageName="Company">
      <SEO
        title="Company"
        description={en_description}
        lang={"en"}
        twitterTitle={"D Capital | Company"}
      />
      <div className={"company-wrapper"}>
        <div className={"company-title"}>
          {companyData?.title && (
            <PageTitle title={companyData.title} key={companyData.id} />
          )}
        </div>
        <div className={"company-item-wrapper"}>
          <div>
            <div className={"company-name-wrapper"}>
              <p className={"company-name-en"}>D Capital, Inc.</p>
            </div>
            <div className={"company-info-container"}>
              {companyData?.founded_date && (
                <div className={"company-info-item"}>
                  <div>
                    <p className={"company-contents-title"}>
                      Date of establishment
                    </p>
                  </div>
                  <div>
                    <p className={"company-contents-body"}>
                      {companyData.founded_date}
                    </p>
                  </div>
                </div>
              )}
              {companyData?.business_overview && (
                <div className={"company-info-item"}>
                  <div>
                    <p className={"company-contents-title"}>Business details</p>
                  </div>
                  <div>
                    <p className={"company-contents-body"}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${companyData.business_overview}`
                        }}
                      />
                    </p>
                  </div>
                </div>
              )}
              {companyData?.address && (
                <div className={"company-info-item"}>
                  <div>
                    <p className={"company-contents-title"}>Location</p>
                  </div>
                  <div>
                    <p className={"company-contents-body"}>
                      {companyData.address}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Company

export const CompanyIndexQueryForEn = graphql`
  query CompanyIndexQueryForEn {
    microcmsCompanyEn {
      address
      business_overview
      founded_date
      title
    }
  }
`
